import React from "react";
import client from "lib/ApolloClient";
import ReactLoading from "react-loading";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import ReactHtmlParser from "react-html-parser";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import JournalResourceListComp from "components/resource/JournalResourceListComp";
import ResourceListTab from "components/resource/ResourceListTab";

const getExhibitionDetail = gql`
  query getExhibitionDetail($searchForm: ExhibitionDetailForm) {
    getExhibitionDetail(Input: $searchForm) {
      subject
      photo
      onlineDate
      offlineDate
      content
      exhibitionSubList {
        exhibitionID
        subject
        photo
      }
      exhibitionPath {
        exhibitionID
        subject
      }
    }
  }
`;

class ExhibitionComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  @autobind
  resourceListComp() {
    if (this.props.searchForm !== undefined) {
      switch (this.props.resourceType) {
        case "journal":
          return (
            <JournalResourceListComp
              searchForm={this.props.searchForm}
              resourceType={this.props.resourceType}
              pageType={this.props.pageType}
              {...this.props}
            />
          );
        case "paper_journal":
          return (
            <JournalResourceListComp
              searchForm={this.props.searchForm}
              resourceType={this.props.resourceType}
              pageType={this.props.pageType}
              {...this.props}
            />
          );
        case "ebook":
          return (
            <JournalResourceListComp
              searchForm={this.props.searchForm}
              resourceType={this.props.resourceType}
              pageType={this.props.pageType}
              {...this.props}
            />
          );
        default:
      }
    }
  }

  @autobind
  setExhibitionID(exhibitionID, e) {
    e.preventDefault();
    let { location } = this.props;
    let params = {};
    params.exhibitionID = exhibitionID;
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  render() {
    let searchBlockType = "resource";
    let { t, searchForm } = this.props;
    let { exhibitionID } = searchForm;
    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query
            query={getExhibitionDetail}
            fetchPolicy="network-only"
            variables={{
              searchForm: {
                id: parseInt(exhibitionID),
              },
            }}>
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <div style={{ textAlign: "center" }}>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </div>
                );
              if (error) return `Error!${error.message}`;
              if (data && data.getExhibitionDetail) {
                let { getExhibitionDetail } = data;
                let {
                  subject,
                  photo,
                  onlineDate,
                  offlineDate,
                  content,
                  exhibitionSubList,
                  exhibitionPath,
                } = getExhibitionDetail;
                let photoPath = "/file/downloadFile/exhibition/" + photo;
                let hasSub = exhibitionSubList && exhibitionSubList.length > 0;
                if (subject) {
                  return (
                    <>
                      <section className="section col-12 theme_book">
                        <div className="container">
                          <div className="col">
                            <div className="theme_data">
                              <div className="images">
                                <img alt={subject} src={photoPath} />
                              </div>
                              <h3>{subject}</h3>
                              <ul className="datalist">
                                <li>
                                  {ReactHtmlParser(
                                    t("jumperrwd.exhibition.exhibtionTime", {
                                      onlineDate: onlineDate,
                                      offlineDate: offlineDate,
                                    })
                                  )}
                                </li>
                              </ul>
                              <div className="explain">
                                {ReactHtmlParser(content)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      {exhibitionPath && exhibitionPath.length > 0 && (
                        <div className="main_content erm">
                          <div className="container">
                            <div className="search_result">
                              <h2>
                                {exhibitionPath.map((pathItem, k) => {
                                  return (
                                    <>
                                      {k !== 0 && <>{" / "}</>}
                                      <a
                                        tabIndex="0"
                                        onClick={this.setExhibitionID.bind(
                                          this,
                                          pathItem.exhibitionID
                                        )}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            this.setExhibitionID(
                                              pathItem.exhibitionID,
                                              e
                                            );
                                          }
                                        }}>
                                        {pathItem.subject}
                                      </a>
                                    </>
                                  );
                                })}
                              </h2>
                            </div>
                          </div>
                        </div>
                      )}
                      {hasSub && (
                        <div className="themesort">
                          <ul>
                            {exhibitionSubList.map((es, k) => {
                              return (
                                <li>
                                  <a
                                    tabIndex="0"
                                    onClick={this.setExhibitionID.bind(
                                      this,
                                      es.exhibitionID
                                    )}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        this.setExhibitionID(
                                          es.exhibitionID,
                                          e
                                        );
                                      }
                                    }}>
                                    <img
                                      alt={es.subject}
                                      src={
                                        "/file/downloadFile/exhibition/" +
                                        es.photo
                                      }
                                    />
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                      {!hasSub && (
                        <>
                          <ResourceListTab
                            resourceType={this.props.resourceType}
                            searchType={searchBlockType}
                            searchForm={this.props.searchForm}
                            {...this.props}
                          />
                          <section className="main_content erm">
                            <div className="container">
                              {this.resourceListComp()}
                            </div>
                          </section>
                        </>
                      )}
                    </>
                  );
                } else {
                  return "";
                }
              }
              return "";
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

export default ExhibitionComp;
